<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'
import orderConfig from '@/orderConfig'
import { orderStateList } from '@/utils/textFile'

export default {
  name: 'farmingExperience',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/farming/order/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '单号',
          type: 'input',
          key: 'orderSn',
          placeholder: '请输入单号'
        },
        {
          name: '出售商品',
          type: 'input',
          key: 'productName'
        },
        {
          name: '店铺名称',
          type: 'input',
          key: 'shopName'
        },
        {
          name: '购买人',
          type: 'input',
          key: 'buyName'
        },
        {
          name: '电话',
          type: 'input',
          key: 'buyPhone',
          placeholder: '请输入购买人电话'
        }
      ]
    },
    // 获取选中订单详情
    getDetailData(id) {
      return new Promise((resolve, reject) => {
        getAction(`/api/farming/order/detail?id=${id}`)
          .then(result => {
            resolve(result.data)
          })
          .catch(e => reject && reject(e))
      })
    },
    onClose(data) {
      orderConfig.close({
        data,
        params: {
          data
        },
        executeFun: [
          // 表格数据
          orderConfig.table,
          // 基础信息
          orderConfig.base,
          // 记录
          orderConfig.record
        ],
        success: ({ setHidden }) => {
          api.order.close
            .call(this, {
              url: '/common/order/close',
              params: { id: data.id }
            })
            .then(setHidden)
        }
      })
    },
    // 退款事件
    onRefund({ records, item }) {
      let that = this
      // type手动传参 告诉当前订单类型
      orderConfig.refund({
        data: records,
        params: { data: { ...records, type: 'eatDrink' } },
        executeFun: [
          // 基础
          orderConfig.base,
          // 优惠券
          orderConfig.coupons,
          // 售后
          orderConfig.afterProcessing,
          // 记录
          orderConfig.record
        ],
        success({ data, setHidden }) {
          api.order.refund
            .call(that, {
              url: '/farming/order/refund',
              params: { ...item, orderId: item.orderId, ...data }
            })
            .then(setHidden)
        }
      })
    },
    getColumns() {
      return [
        {
          dataIndex: 'orderSn',
          title: '单号',
          align: 'left',
          isId: true
        },
        {
          dataIndex: 'productName',
          title: '出售商品',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                {records.orderItemList.map(e => (
                  <div class="product-order-item">
                    <img src={e.productPic} />
                    <div>{e.productName}</div>
                  </div>
                ))}
              </div>
            )
          }
        },
        {
          title: '店铺名称',
          dataIndex: 'shopName',
          align: 'left'
        },
        {
          dataIndex: 'productQuantity',
          title: '数量',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                {records.orderItemList.map(e => {
                  return <div>{e.productQuantity}</div>
                })}
              </div>
            )
          }
        },
        {
          dataIndex: 'payAmount',
          title: '实付金额',
          align: 'left',
          customRender: (text, records) => {
            if (records.refundAmount) {
              return (
                <div>
                  <span>{records.payAmount.toFixed(2)}</span>
                  <span>（{records.refundAmount.toFixed(2)} 已退）</span>
                </div>
              )
            } else {
              return <div>{records.payAmount.toFixed(2)}</div>
            }
          }
        },
        {
          dataIndex: 'status',
          title: '订单状态',
          align: 'left',
          customRender: text => (orderStateList.find(e => e.value == text) || { text: '' }).text,
          onExport: text => (orderStateList.find(e => e.value == text) || { text: '' }).text,
          filterMultiple: false,
          filters: orderStateList
        },

        {
          dataIndex: 'buyName',
          title: '购买人',
          align: 'left'
        },
        {
          dataIndex: 'buyPhone',
          title: '电话',
          align: 'left'
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '主动退款',
                display:
                  records.orderItemList[0].returnStatus == '0' &&
                  records.status != '6' &&
                  records.status != '5' &&
                  records.status != '4' &&
                  records.status != '0',
                onClick: () =>
                  this.getDetailData(records.id).then(e =>
                    this.onRefund({
                      item: records.orderItemList[0],
                      records: e
                    })
                  )
              }
              //   {
              //     name: '关闭',
              //     display: records.status == '0',
              //     onClick: () => this.getDetailData(records.id).then(this.onClose)
              //   }
            ].filter(e => e.display)
          }
        }
      ]
    },
    // 批量删除
    onBatchDelete(keys) {
      return api.order.batchClose.call(this, {
        url: '/common/order/closeBatch',
        params: { idList: keys.map(e => e.id) }
      })
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量关闭',
              onClick: this.onBatchDelete
            }
          ]
        }
      ]
    },

    onRowSelect(records) {
      const ext = [
        // 表格
        orderConfig.table,
        // 退款信息
        orderConfig.retuenInfo,
        // 基本信息
        orderConfig.base,
        // 优惠券
        orderConfig.coupons,
        // 记录
        orderConfig.record
      ]
      this.getDetailData(records.id).then(e => {
        orderConfig.open({
          data: e,
          params: { data: e },
          executeFun: ext
        })
      })
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        onSelectRow={this.onRowSelect}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 5px;
    object-fit: cover;
  }
}
.product-number {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  div {
    height: 30px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
</style>
